<template>
    <div class="progressbar">
        <span>Question {{ questionIndex + 1 }} / {{ questions.length }}</span>
        <div class="meter-gauge">
            <div v-for="(step, index) in questions.length"
                 :index="index"
                 :class="{ 'active': questionIndex >= index }">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ProgressBar"
    }
</script>

<script setup>

    import {useMainStore} from "../stores/main";
    import {storeToRefs} from "pinia";

    let store = useMainStore();
    const { questions } = storeToRefs(store);
    const { questionIndex } = storeToRefs(store);

</script>