/**
 * Author: Martin Tremblay - martin@lesaffranchis.ca
 * Goal: Proxified an object with a dynamic/changing schema
 *
 * If the path to the object does not existing, the path will be returned instead of the "value" of the path.
 * ex: copydeck.menu.buttons.share.fr will return either 'Partagez' or 'copydeck.menu.buttons.share.fr'
 */
import _              from "lodash";
import {useMainStore} from "../stores/main";
import {storeToRefs}  from "pinia";

let store;
let copydeck, lang;

//const {copydeck, lang} = storeToRefs(store);

export default function getCopydeck ()
{

    if (!store) //first time we instantiate, we will link to the store
    {
        store = useMainStore();

        ({copydeck, lang} = storeToRefs(store)); //parentheses are needed to destructure into existing variable
    }

    const handler =
              {
                  get: (target, name) =>
                       {
                           let obj = _.get(target, name);

                           if (obj === undefined)
                           {
                               let type = typeof name;

                               if (type === "symbol")
                               {
                                   //if we have not found something, we check if we can find a localized version
                                   let loc = _.get(target, lang.value);

                                   if (loc)
                                   {
                                       return () => loc;
                                   }
                               }

                               return name;
                           }
                           else if (typeof obj === "string")
                           {
                               return obj
                           }

                            if (typeof obj !== "object" && typeof obj !== "function")
                            {
                                return obj;

                            }

                           return new Proxy(obj, handler);
                       },


              };

    return new Proxy(copydeck.value, handler);
}






