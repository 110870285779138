import { createApp } from 'vue'
import {createRouter, createWebHistory} from 'vue-router'
import App           from "./vue/App";
import { createPinia } from 'pinia'
import routes from "./vue/routes";


function onReady ()
{
    const el = document.querySelector("#container");

    const pinia = createPinia()
    const vue = createApp( App );

    const router = createRouter(
        {
            history: createWebHistory(),
            routes,
            scrollBehavior(to, from, savedPosition) {
                // always scroll to top
                return { top: 0 }
            }
        });

    vue
        .use(pinia)
        .use(router)
        .provide("strGoogleTag", googleTag ?? 'GTM-NRZNSKC')
        .mount(el);



    window.postMessage({
                           devtoolsEnabled: true,
                           vueDetected: true
                       }, '*')
}

document.addEventListener( 'DOMContentLoaded', onReady );
