<template>
    <li class="choice" :class="`c-${index + 1}`">
        <figure class="choice-img" aria-hidden="true">
            <img :src="imageSrc" />
            <blob :percent="(showAnswer)? percent : 0" />
        </figure>

        <span v-html="label" class="choice-text"></span>

        <div class="choice-value">
            <div :class="{'hidden' : !showAnswer}">
                <counter
                    :value="(showAnswer)? carbon_grams : 0"
                    :locale="locale"
                ></counter>
            </div>
        </div>
        <div class="cloud" :class="`cloud--${index + 1}`" v-if="showAnswer"></div>
    </li>
</template>

<script>
    import Blob from "./Blob";
    import Counter from "./Counter";

    export default {
        name: "Choice",
        components: { Blob, Counter }
    }
</script>

<script setup>

    import {useMainStore} from "../stores/main";
    import {storeToRefs}  from "pinia";
    import { computed } from "vue";

    let props = defineProps(["index", "showAnswer", "question"]);

    let store = useMainStore();

    let {questions, lang} = storeToRefs(store);

    let selectedAnswer = props.question.answers[props.question.choice_matters.index_answer];
    let allCarbonGrams = questions.value.map ( q => parseInt(q.answers[q.choice_matters.index_answer].carbon_grams ?? 0)) //Get carbon value for all "choice matters"
    let max_carbon_grams = Math.max(...allCarbonGrams); //get largest carbon value from choice matters

    let label = computed( () => props.question.choice_matters.text[lang.value]);
    let carbon_grams =  parseInt(selectedAnswer.carbon_grams ?? 0);

    let percent =  parseInt(((selectedAnswer.carbon_grams / max_carbon_grams) * 100) ?? 0);
    let imageSrc = selectedAnswer.image_monochrome.url;

    let locale = computed ( () => `${lang.value}-CA`);

</script>
