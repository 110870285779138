<template>

    <div class="page-content size-large">
        <blob />

        <div class="box">
            <h1 class="title-h2">{{ copydeck.data.title }}</h1>
            <div class="scrollable">
                <div v-html="copydeck.data.data_information"></div>
            </div>
        </div>

        <div class="page-buttons">
            <router-link class="btn" to="/">{{ copydeck.end.button }}</router-link>
        </div>

    </div>

    <navbar />

</template>

<script>
    import Navbar from "../components/Navbar";
    import Blob from "../components/Blob";

    export default {
        name: "Info",
        components: {Blob, Navbar}
    }
</script>

<script setup>
    import useCopydeck from "../composables/UseCopydeck";
    const copydeck = useCopydeck();
</script>
