<template>
    <div class="page-content size-large">
        <div class="box">
            <div>
                <h1 class="title-h2" v-if="!isShowAnswer">{{ copydeck.notallequals.title }}</h1>
                <h1 class="title-h2" v-if="isShowAnswer">{{ copydeck.notallequals.title2 }}</h1>

                <ul class="choices">
                    <choice
                        v-for="(currentQuestion,index) in choiceMattersSortByLessCarbon"
                        :showAnswer="isShowAnswer"
                        :question="currentQuestion"
                        :index="index"></choice>
                </ul>
            </div>
        </div>
        <div class="page-buttons">
            <button type="button" class="btn" @click="nextStep">{{ copydeck.button.continue }}</button>
        </div>
    </div>
    <navbar />
</template>

<script>
    import Choice from "../components/Choice";
    import Navbar from "../components/Navbar";
    export default {
        name: "Choices",
        components: { Navbar, Choice }
    }
</script>

<script setup>
    import { useRouter } from "vue-router";
    import { useMainStore } from "../stores/main";
    import useCopydeck from "../composables/UseCopydeck";
    import {storeToRefs} from "pinia";
    import {computed, ref} from "vue";

    const router = useRouter();
    const copydeck = useCopydeck();

    let store = useMainStore();

    const { lang, questions, sequenceIndex, i_will_take_action } = storeToRefs(store);

    let step = ref('hide');

    let isShowAnswer = computed ( () => step.value === 'show');
    let showResultsAgain = computed ( () => sequenceIndex.value === 1  );

    let choiceMattersSortByLessCarbon = computed ( () =>
    {
        //slice avoid changing the original array
        return questions.value.slice().sort( (a,b) =>  getCarbon(a) - getCarbon(b) );

    });

    function getCarbon (question)
    {
        return parseInt(question.answers[question.choice_matters.index_answer].carbon_grams ?? 0);
    }

    function nextStep()
    {
        if(step.value === 'show') {
            if(i_will_take_action.value)
            {
                router.push( {name: "TakeAction-" + lang.value} );
            }
            else
            {
                router.push( {name: "ThankYou-" + lang.value} );
            }
        } else {
            step.value = "show";
            window.scrollTo(0, 0)
        }
    }

</script>
