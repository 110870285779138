<template><div></div></template>
<script>
    import Confetti from "vue-confetti/src/confetti.js";
    export default {
        data: () => ({
            confetti: new Confetti(),
        }),
        mounted: function () {
            this.confetti.start({
                defaultType: 'rect',
                windSpeedMax: 0,
                defaultColors: ["#fcb414", "#d4df28", "#06b7c4", "#ed008c", "#91298c"],
            });
            setTimeout(() => {this.confetti.stop()}, 5000);
        },
        methods: {},
    };
</script>