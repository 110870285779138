<template>
    <div class="page-content">

        <div class="blobs">
            <blob class="blob-1 c-4" style="--p:65;" />
            <blob class="blob-2 c-1" style="--p:180;" />
            <blob class="blob-3 c-3" style="--p:33;" />
            <blob class="blob-4 c-2" style="--p:70;" />
        </div>

        <div class="box">
            <div class="box-content" v-if="!isAllGood">
                <p class="t-huge t-center">{{ copydeck.again.text }}</p>
            </div>
            <div class="box-content" v-else>
                <h1 class="title-h2">{{ copydeck.success.title }}</h1>
                <p>{{ copydeck.success.text }}</p>
            </div>
        </div>

        <div class="page-buttons">
            <button type="button" class="btn" @click="restart" v-if="tryAgain">{{ copydeck.button.yes }}</button>
            <button type="button" class="btn" @click="end" v-if="tryAgain">{{ copydeck.button.no }}</button>
            <button type="button" class="btn" @click="end" v-if="finalEnding">{{ copydeck.end.button }}</button>
            <button type="button" class="btn" @click="nextStep" v-if="!tryAgain && !finalEnding">{{ copydeck.button.continue }}</button>
        </div>
    </div>
    <navbar />
    <Confetti v-if="isAllGood"/>
</template>

<script>
    import Blob from "../components/Blob";
    import Navbar from "../components/Navbar";
    import Confetti from "../components/Confetti";

    export default {
        name: "QuizEnd",
        components: { Confetti, Blob, Navbar }
    }
</script>

<script setup>
    import { vue, computed }     from "vue";
    import { useRouter }    from "vue-router";
    import { useMainStore } from "../stores/main";
    import useCopydeck      from "../composables/UseCopydeck";
    import { storeToRefs }  from "pinia";
    //import { event } from 'vue-gtag'
    import { event } from "../composables/UseGtag";

    const router = useRouter();
    const copydeck = useCopydeck();
    let store = useMainStore();

    const { sequenceIndex, scores, questions, lang } = storeToRefs(store);
    const { nextSequence } = store;

    // finding the lowest possible score
    let minScores = questions.value.reduce ( (a, q) =>
    {
        const cg = q.answers.map ( a => a.carbon_grams);

        let minCG = Math.min.apply(Math, cg);

        return a + minCG;

    }, 0)

    let isAllGood = computed( () => sequenceIndex.value === 0 && scores.value[0] <= minScores);
    let tryAgain = computed( () => sequenceIndex.value === 0 && scores.value[0] > minScores);
    let finalEnding = computed( () => sequenceIndex.value === 1 || scores.value[0] <= minScores);

    function restart()
    {
        event('quiz', "Restart", "");

        nextSequence();
        router.push( {name: "Quiz"} );
    }

    function nextStep() {
        router.push( {name: "ThankYou-" + lang.value} );
    }

    function end() {
        router.push( {name: "Splash" } );
    }
</script>
