<template>
    <div ref="curve" class="curve mobile-hide"></div>
</template>

<script>
    export default {
        name: "Curve"
    }
</script>

<script setup>

    import { onMounted, ref } from "vue";
    import Two               from "two.js";
    import { useWindowSize } from "@vueuse/core";

    const two = new Two({width:"100%", height: "100%"})
    let curve = ref(null);

    const { width, height } = useWindowSize();

    let lastPosition = [];


    function drawCurve (position = [])
    {
        two.clear();

        const offset = width.value / 5;
        const startY = height.value * 0.6;

        let vertices = [];

        vertices.push(new Two.Anchor(0, startY, null, null, null, null, Two.Commands.move))

        let endY = startY;

        if (position.length > 0)
        {
            //this is mainly for debugging purpose
            lastPosition = position;
        }
        else
        {
            let a = 1;
        }

        for (let i = 0; i < position.length; i++)
        {
            let pt = position[i];

            let a = new Two.Anchor(0,0,0, 0, 0, 0, Two.Commands.curve);

            let x = pt.x;
            let y = startY - (pt.y ?? 0);

            let command = Two.Commands.curve;

            let anchor = new Two.Anchor(x, y, null, null, null, null, command);

            vertices.push(anchor)

            endY = y;
        }

        //width*4 is a hack to avoid having a bump at the end of the curve
        vertices.push(new Two.Anchor(width.value * 4 , endY, null, null, null, null, Two.Commands.curve))
        vertices.push(new Two.Anchor(width.value * 4, 0, null, null, null, null, Two.Commands.curve))
        vertices.push(new Two.Anchor(0,  0, null, null, null, null, Two.Commands.curve))
        vertices.push(new Two.Anchor(0, startY, null, null, null, null, Two.Commands.curve))

        // let line = two.makePath(vertices);
        // line.fill = "#009999";
        // line.automatic = false;

        var curve = position.length > 0 && true ? two.makeCurve(vertices) : two.makePath(vertices);
        curve.linewidth = 0;
        curve.scale = 1.0;

        // const el = ref(null)
        // const color = useCssVar('--color-pale', el)
        // curve.fill = color.value;

        two.update();

    }

    function mounted ()
    {
        two.appendTo(curve.value);
    }

    onMounted(mounted);
    defineExpose( { drawCurve });

</script>
