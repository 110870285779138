<template>
    <dialog ref="refModal" class="modal" :class="{'hidden': isOpen.value}">
        <div class="blobs">
            <blob class="blob-2 c-1" />
            <blob class="blob-3 c-3" />
        </div>
        <div class="modal-content">
            <h1 class="title-logo">Mission <strong>{{ copydeck.text.zero[lang] }}</strong></h1>

            <button @click="close" class="modal-close-btn">
                <svg aria-hidden="true" width="48px" height="48px" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
                    <path d="M202.82861,197.17188a3.99991,3.99991,0,1,1-5.65722,5.65624L128,133.65723,58.82861,202.82812a3.99991,3.99991,0,0,1-5.65722-5.65624L122.343,128,53.17139,58.82812a3.99991,3.99991,0,0,1,5.65722-5.65624L128,122.34277l69.17139-69.17089a3.99991,3.99991,0,0,1,5.65722,5.65624L133.657,128Z" />
                </svg>
                <span class="sr-only">{{ copydeck.button.close[lang] }}</span>
            </button>

            <div v-html="copydeck.modal.text[lang]"></div>
            <div class="timetracker" :style="`--p:${ parseFloat(percent) };`"><span class="sr-only">{{ timeRemaining }} {{ copydeck.text.sec_left[lang] }}</span></div>

            <button @click="close" v-html="copydeck.modal.yes[lang]" class="btn"></button>
        </div>

    </dialog>
</template>

<script>
import Blob from "../components/Blob";
export default {
    name: "Micromodal",
    components: {Blob}
}
</script>

<script setup>

    import { ref, onMounted }         from "vue";
    import {useMainStore}             from "../stores/main";
    import {storeToRefs}              from "pinia";
    import {throttledWatch, useMouse} from "@vueuse/core";
    import {useRoute}                 from "vue-router";

    let store = useMainStore();
    const { copydeck, lang } = storeToRefs(store)
    const { x, y } = useMouse();

    let route = useRoute();

    let minDelay = 60000;
    let idTimer;
    let timeAllowed = 10 * 1000; //10 secs
    let timeRemaining = 0;
    let timerDate;

    let refModal = ref(null);
    let percent = ref(0);
    let isOpen = ref(false);

    function resetTimeout ()
    {
        if (idTimer)
        {
            clearInterval(idTimer);
        }

        idTimer = setTimeout( () => show(), minDelay);
    }

    throttledWatch(
        [x, y],
        ([x, y]) =>
        {
            resetTimeout();
        },
        { throttle: 1000 / 60 }
    );

    document.addEventListener("keyup", onKeyUp);

    function onKeyUp (event)
   {
       resetTimeout()
   };

    let emits = defineEmits(["restart"]);

    resetTimeout();


    function show ()
    {
        resetTimeout();

        let name = route.name;

        if ("Splash" === route.name || isOpen.value) //Don't display the modal
        {
            return;
        }

        isOpen.value = true;
        refModal.value.showModal();
        timerDate = new Date().getTime();
        startTimer();

    }

    function startTimer()
    {
        if (!isOpen.value)
        {
            return;
        }

        let now = new Date().getTime();
        let elapsed = now - timerDate;

        percent.value = elapsed / timeAllowed;

        if (percent.value >= 1)
        {
            restart();
        }
        else
        {
            requestAnimationFrame( startTimer );
        }
    }

    function close ()
    {
        isOpen.value = false;
        refModal.value.close()
        resetTimeout();
    }

    function restart ()
    {
        close()
        resetTimeout();

        emits("restart")
    }

    function mounted ()
    {
    }

    onMounted( mounted );


</script>


