<template>

    <div class="page-content take-action size-large">

        <div class="box">
            <h1 class="title-h2" v-html="copydeck.actions.title"></h1>

            <ul class="actions">
                <action
                        v-for="(currentQuestion,index) in questionsPlusOther"
                        :index="index"
                        :current-question="currentQuestion"
                        :handleClick="selectAnswer"
                        ref="refAnswers"
                ></action>


            </ul>
        </div>

        <div class="page-buttons">
            <button class="btn" type="button" :disabled="!canSubmit" @click="nextStep">{{ copydeck.button.continue }}</button>
        </div>
    </div>

    <navbar />

    <svg width="0" height="0" aria-hidden="true">
        <defs>
            <clipPath id="checkmark" clipPathUnits="objectBoundingBox">
                <path d="M.47.603.721.044a.134.134 0 1 1 .199.18L.591.956A.134.134 0 0 1 .382.943.22.22 0 0 1 .362.92L.067.488A.134.134 0 0 1 .292.342L.47.603Z" />
            </clipPath>
        </defs>
    </svg>

</template>

<script>
    import Action from "../components/Action";
    import Navbar from "../components/Navbar";
    import Blob from "../components/Blob";
    export default {
        name: "TakeAction",
        components: {Action, Blob, Navbar}
    }
</script>

<script setup>

    import { useRouter } from "vue-router";
    import useCopydeck from "../composables/UseCopydeck";
    import { storeToRefs } from "pinia";
    import { useMainStore } from "../stores/main";
    import { computed, ref } from "vue";
    import axios from "axios";

    const copydeck = useCopydeck();
    const router = useRouter();
    let store = useMainStore();

    const { lang, uuid, questions } = storeToRefs(store);
    let refAnswers = ref(null);
    let otherChoice = ref(null);
    let step = ref('ask');

    let canSubmit = computed( () => refAnswers.value && refAnswers.value.find ( a => a.isSelected ) );

    let questionsPlusOther = JSON.parse(JSON.stringify(questions.value))
    questionsPlusOther.push ( { take_action: { image:"", text: copydeck.actions.other, type: "no action"} } );

    function selectAnswer(index, value)
    {
        let selectedAnswer;

        selectedAnswer = refAnswers.value[index];

        /* // removed on request
        if (index === (refAnswers.value.length -1)) //we have selected the last one
        {
            refAnswers.value.forEach( a => a.setPicked(false));
        }
        else
        {
            refAnswers.value[refAnswers.value.length -1].setPicked(false);
        }
        */

        selectedAnswer.setPicked(value);
    }

    function nextStep()
    {
        if (refAnswers.value) //only if user chose 'yes'
        {
            const data = {
                uuid:    uuid.value,
                lang:    lang.value,
                actions: refAnswers.value.filter(r => r.isSelected).map(r => r.action)
            };
            axios.post("/endpoints/save-user-action", data);
        }

        router.push({ name: "ThankYou-" + lang.value } );
    }

</script>
