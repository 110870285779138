<template>
    <div class="page-content size-large" v-if="!showActions">
        <div class="box">
            <div v-if="showCompare">
                <h1 class="title-h2">{{ copydeck.comparison.title }}</h1>
                <p>{{ copydeck.comparison.text }}</p>
            </div>

            <div v-else>
                <h1 class="title-h2" v-if="showResults">{{ copydeck.quizfeedback.title }}</h1>
                <p v-if="showResults">{{ strTextFeedback1 }}</p>

                <h1 class="title-h2" v-if="showResultsAgain">{{ copydeck.quizfeedbackagain.title }}</h1>
                <p v-if="showResultsAgain">{{ strTextFeedback2 }}</p>

                <ul class="results">
                    <result
                        v-for="(currentQuestion,index) in questions"
                        :sequenceIndex="sequenceIndex"
                        :index="index"></result>
                </ul>
            </div>
        </div>

        <div class="page-buttons">
            <button type="button" class="btn" @click="nextStep">{{ copydeck.button.continue }}</button>
        </div>
    </div>

    <div class="page-content" v-if="showActions">
        <blob class="c-2" />

        <div class="box">
            <div class="box-content">
                <h1 class="title-h2" v-html="copydeck.willyou.title"></h1>
                <div v-html="copydeck.willyou.text"></div>
            </div>
        </div>

        <div class="page-buttons">
            <button class="btn" type="button" @click="yes">{{ copydeck.button.yes }}</button>
            <button class="btn" type="button" @click="no">{{ copydeck.button.no }}</button>
        </div>
    </div>

    <navbar />
</template>

<script>
    import Result from "../components/Result";
    import Navbar from "../components/Navbar";
    export default {
        name: "Results",
        components: { Navbar, Result }
    }
</script>

<script setup>
    import { computed, ref } from "vue";
    import { useRouter } from "vue-router";
    import { useMainStore } from "../stores/main";
    import useCopydeck from "../composables/UseCopydeck";
    import {storeToRefs} from "pinia";
    import axios from "axios";

    const router = useRouter();
    const copydeck = useCopydeck();

    let store = useMainStore();

    const { lang, uuid, sequenceIndex, scores, questions, i_will_take_action } = storeToRefs(store);
    let step = ref('results' );

    let showResults = computed ( () => sequenceIndex.value === 0 );
    let showResultsAgain = computed ( () => sequenceIndex.value === 1  );
    let showCompare = false; // computed ( () => step.value === 'compare'  );
    let showActions = computed( () => step.value === 'actions' );

    let strTextFeedback1 = computed ( () => copydeck.quizfeedback.text[lang.value].replace("[number]", scores.value[0].toLocaleString(`${lang.value}-CA`)))
    let strTextFeedback2 = computed ( () => getFeedbackFromDiff(scores));

    function getFeedbackFromDiff ( scores )
    {
        let score0 = scores.value[0];
        let score1 = scores.value[1];

        let diff = Math.abs(score0 - score1);
        let strFeedback = "";

        if (score0 > score1)
        {
            strFeedback = copydeck.quizfeedbackagain.text_if_less[lang.value];
        }
        else if (score1 > score0)
        {
            strFeedback = copydeck.quizfeedbackagain.text_if_more[lang.value];
        }
        else
        {
            strFeedback = copydeck.quizfeedbackagain.text_if_same[lang.value];
        }

        strFeedback = strFeedback.replace("[number]", score1.toLocaleString(`${lang.value}-CA`));
        strFeedback = strFeedback.replace("[diff]", diff.toLocaleString(`${lang.value}-CA`));

        return strFeedback;
    }

    function nextStep()
    {
        step.value = 'actions';
        window.scrollTo(0, 0);
    }

    function yes() {
        i_will_take_action.value = true;

        const data = {
            uuid:    uuid.value,
            lang:    lang.value,
            i_will_take_action: 1
        };
        axios.post("/endpoints/save-user-action", data);

        router.push({ name: "Choices-" + lang.value } );
    }

    function no() {
        i_will_take_action.value = false;

        const data = {
            uuid:    uuid.value,
            lang:    lang.value,
            i_will_take_action: 0
        };
        axios.post("/endpoints/save-user-action", data);

        router.push({ name: "Choices-" + lang.value } );
    }
</script>
