<template>

    <question
        :question="questions[questionIndex]"
        :question-index="questionIndex"
        :index="`q-${sequenceIndex}-${questionIndex}`"
    ></question>

    <navbar />

</template>

<script>
import Navbar from "../components/Navbar";
import Question from "../components/Question"

export default
{
    name: "Quiz",
    components: { Navbar, Question }
}
</script>

<script setup>

    import { storeToRefs }  from "pinia";
    import { useMainStore } from "../stores/main";
    import { onMounted } from "vue";

    let store = useMainStore();
    const { questions, sequenceIndex, questionIndex  } = storeToRefs(store)

    function mounted ()
    {
        window.scrollTo(0, 0);
    }

    onMounted( mounted )
</script>
