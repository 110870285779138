<template>

	<nav class="navbar" role="navigation">
		<div class="switch-language">
			<button type="button" class="btn" :class="{'btn-active': lang === 'en'}" @click="setLang('en')">English</button>
			<button type="button" class="btn" :class="{'btn-active': lang === 'fr'}" @click="setLang('fr')">Français</button>
		</div>

		<div class="adjust-fontsize">
			<button type="button" class="btn-text" @click="zoomLess" :disabled="!canZoomLess">-<span class="sr-only">{{ copydeck.button.zoom_less }}</span></button>
			<span aria-hidden="true">Aa</span>
			<button type="button" class="btn-text" @click="zoomMore" :disabled="!canZoomMore">+<span class="sr-only">{{ copydeck.button.zoom_more }}</span></button>
		</div>

		<div class="back-to-home">
			<router-link to="/" class="btn">{{ copydeck.menu.home_button }}</router-link>
		</div>
	</nav>

</template>

<script>
	export default {
		name: "Navbar"
	}
</script>

<script setup>

	import { ref, computed } from "vue";
	import { useMainStore } from "../stores/main";
	import useCopydeck    from "../composables/UseCopydeck";
	import {storeToRefs} from "pinia";
    import { useRouter }     from "vue-router";

	const copydeck = useCopydeck();

	let store = useMainStore();

	const { changeLang } = store;
	const { lang, currentZoom } = storeToRefs(store);
    const router = useRouter();

	const maxZoom = 3;
	const rootEl = document.documentElement;

	const canZoomMore = computed(() => currentZoom.value < maxZoom);
	const canZoomLess = computed(() => currentZoom.value > 1);

	function zoomLess() {
		currentZoom.value = (currentZoom.value > 1)? currentZoom.value - 1 : currentZoom.value;
		rootEl.setAttribute('data-zoom', currentZoom.value);
	}

	function zoomMore() {
		currentZoom.value = (currentZoom.value < maxZoom)? currentZoom.value + 1 : currentZoom.value;
		rootEl.setAttribute('data-zoom', currentZoom.value);
	}

	function setLang (lang) {
		// I was trying to make the screen reader read with the correct accent, but it simply won't
		// document.querySelector('html').setAttribute('lang', lang);
		changeLang(lang);

        let name = router.currentRoute.value.name;

        name = name.replace(/-(fr|en)/, `-${lang}`)

        router.push({name, params:{ lang } } );

	}

</script>
