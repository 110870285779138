<template>

    <div class="page" v-if="ready">

        <router-view></router-view>

<!--        <div class="debug-grid">-->

<!--            <div v-for="i in 192*108" class="debug-cell" :style="randBgColor(i)"></div>-->

<!--        </div>-->

        <micromodal v-if="showTimer" @restart="restart"></micromodal>

    </div>

    <div class="loading" v-else> <span class="sr-only">LOADING</span> </div>
</template>

<script>

    export default
    {
        name: "App",
        components: {}
    }

</script>


<script setup>

    import { onMounted, computed, inject } from "vue";
    import {useMainStore}          from "./stores/main";
    import {storeToRefs}           from "pinia";
    import {useRoute, useRouter}   from "vue-router";
    //import { event, pageview }     from 'vue-gtag';
    import { useTitle, useUrlSearchParams }            from '@vueuse/core';
    import Micromodal              from "./components/Micromodal";
    //import useCopydeck             from "./composables/UseCopydeck";

    import { pageView } from "./composables/UseGtag";

    let store = useMainStore();
    const { loadDatas } = store;
    const { ready, user, lang, userIsReady, copydeck } = storeToRefs(store)
    const { reset, setGoogleTag } = (store)
    //let copydeck = useCopydeck();

    let strGoogleTag = inject("strGoogleTag");


    let router = useRouter();
    let route = useRoute();
    let title = useTitle()

    let isSplashPage = computed ( () => ["Splash"].includes(route.name));
    let isQuizPage = computed ( () => !["Splash","Menu"].includes(route.name));

    const params = useUrlSearchParams('history')
    let showTimer = computed ( () => params.timer === "true");


    function randBgColor ()
    {
        let color = Math.random() * 0xFFFFFF >> 0;
        let strColor = "#" + color.toString(16);
        let strStyle = `background-color:${strColor}`;

        return strStyle;
    }
    function checkState ()
    {

        if (userIsReady.value && isSplashPage.value) //user is defined, but we are at home page
        {
            reset();
        }
        else if (!userIsReady.value && isQuizPage.value) //user is not defined, but we are not on Splash or settings
        {
            router.push({name: "Splash"} )
        }

        router.afterEach( route => changePageTitle(route))
        changePageTitle(route);

    }

    function restart ()
    {
        reset();
        router.push({name: "Splash"} )
    }

    function changePageTitle (route)
    {
        let strLang = lang ? lang.value : "en";

        let groupPageTitle = copydeck.value.pageTitle;

        let strSiteTitle = groupPageTitle.siteTitle[strLang] ?? "Mission Zero";
        let strRouteName = route.name.replace(/-(en|fr)/, "");
        let strPageTitle = groupPageTitle[strRouteName]?.[strLang] ?? route.name;
        let strSeparator = groupPageTitle.separator[strLang];

        title.value = `${strPageTitle} ${strSeparator} ${strSiteTitle}`;
        pageView();

        //pageview( title.value )
    }



    onMounted(async () =>
    {
        await loadDatas();

        setGoogleTag(strGoogleTag);

        router.isReady ().then ( checkState )

    })






</script>


<style>
    .debug-grid
    {
        position: absolute;
         width: 100vw;
         height: 100vh;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(10px, 1fr));
        grid-template-rows: repeat(auto-fill, minmax(10px, 1fr));
        grid-column-gap: 0px;
        grid-row-gap: 0px;
    }

    .debug-cell
    {

    }

</style>
