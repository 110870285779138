import { defineStore } from 'pinia'
import { ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import { useStorage } from "@vueuse/core"


export const useMainStore = defineStore('main', () =>
{
    let router = useRouter();
    let route = useRoute();

    const copydeck = ref([]);
    const questions = ref([]);
    const googleTag = ref("");

    const questionIndex = ref(useStorage('carbon-footprint.questionIndex', 0));
    const sequenceIndex = ref(useStorage('carbon-footprint.sequenceIndex', 0));
    const uuid = ref(useStorage('carbon-footprint.uuid', uuidv4()));

    const availableLang = ["en", "fr"];
    const lang = ref("en");
    const ready = ref(false)
    const currentZoom = ref(2);
    let i_will_take_action = ref(false);

    const userAnswers = ref(useStorage('carbon-footprint.userAnswers', [[],[]]))
    const user = ref(useStorage('carbon-footprint.user', {}))
    const userIsReady = computed( () => user.value.location && user.value.age);
    const scores = computed ( () => calculateScores(userAnswers.value))


    router.isReady ().then ( setLangFromRoute ); //if a lang is set in the route, that what we want

    async function loadDatas ()
    {
        let response = await axios.get("/endpoints/datas");

        copydeck.value = response.data.copydeck;
        questions.value = response.data.questions;

        ready.value = true;
    }

    function changeLang ( strLang )
    {
        if (availableLang.includes(strLang))
        {
            lang.value = strLang;
        }
    }

    function setLangFromRoute ()
    {
        let routeLang = route.params.lang ?? "en";
        changeLang(routeLang);
    }

    function changeQuestion ( index )
    {
        if (questions.value[index] !== undefined)
        {
            questionIndex.value = index;
        }

        if(index >= questions.value.length)
        {
            let currentLang = lang.value ?? route.params.value.lang ?? "en";
            router.push({name: "Results-" + currentLang} );
        }
    }

    function clearAnswers ( )
    {
        userAnswers.value = [[],[]];
    }


    function reset ( )
    {
        clearAnswers();
        questionIndex.value = 0;
        sequenceIndex.value = 0;
        user.value = {};
        uuid.value = uuidv4();
    }

    function setGoogleTag ( strGoogleTag )
    {
        googleTag.value = strGoogleTag;
    }

    function setUserAnswer ( answer )
    {
        userAnswers.value[ sequenceIndex.value ][ questionIndex.value ] = answer
    }

    function getUserAnswer ( sequenceIndex, questionIndex )
    {
        return userAnswers.value[ sequenceIndex ][ questionIndex ]
    }

    function nextSequence ()
    {
        if (sequenceIndex.value === 0)
        {
            sequenceIndex.value = 1;
            questionIndex.value = 0;
        }
    }

    function calculateScores ( userAnswers )
    {
        let score1 = userAnswers[0].reduce ( (a,r) => a += parseInt(r.carbon_grams), 0);
        let score2 = userAnswers[1].reduce ( (a,r) => a += parseInt(r.carbon_grams), 0);

        return [score1, score2];
    }

    function increaseQuestionPopularity (question, index_answer)
    {
        let indexQuestion = questions.value.findIndex( q => q.id === question.id);

        let indexMostPopular = questions.value[indexQuestion].most_popular.findIndex( mp => mp.index_answer === index_answer);

        if (indexMostPopular > -1)
        {
            questions.value[indexQuestion].most_popular[indexMostPopular].popular++;
        }
        else //this should not happen when all answer have been answer at least once
        {
            const id_question = question.id;
            const popular = 1;

            const newAnswer = { index_answer, id_question, popular};
            questions.value[indexQuestion].most_popular.push(newAnswer);
        }

    }

    return {
        availableLang,
        copydeck,
        googleTag,
        questions,
        questionIndex,
        lang,
        currentZoom,
        ready,
        scores,
        sequenceIndex,
        userAnswers,
        user,
        userIsReady,
        uuid,
        i_will_take_action,

        changeLang,
        clearAnswers,
        changeQuestion,
        increaseQuestionPopularity,
        loadDatas,
        nextSequence,
        reset,
        getUserAnswer,
        setUserAnswer,
        setGoogleTag
    };
})
