/**
 * Author: Martin Tremblay martin@lesaffranchis.ca
 *
 * Goal: Singleton to call handler(s) with rand coordinate if the mouse didn't move for a couple seconds.
 * The timer is reset each time the mouse move
 */


import {
    debouncedWatch,
    throttledWatch,
    useMouse,
    useWindowSize,
} from "@vueuse/core";

const { x, y } = useMouse();
let { width, height } = useWindowSize()
let startDelay = 6000;

let minDelay = 2000;
let idTimer;
let arrHandler = [];

function resetTimeout ()
{
    if (idTimer)
    {
        clearInterval(idTimer);
    }

    let delay = minDelay + (minDelay * Math.random()) >> 0;
    idTimer = setTimeout( () => triggerRandomCoordinate(), delay);
}

function triggerRandomCoordinate ()
{

    let xRand = width.value * Math.random();
    let yRand = height.value * Math.random();

    minDelay *= 0.9;

    if (minDelay < 500)
    {
        minDelay = 500;
    }

    resetTimeout();

    arrHandler.forEach( handler => handler(xRand, yRand));
}


throttledWatch(
    [x, y],
    ([x, y]) =>
    {
        resetTimeout();
        minDelay = startDelay;
    },
    { throttle: 1000 / 60 }
);

resetTimeout();


export function useRandomCoordinate (handler)
{
    arrHandler.push(handler);
}
