<template>

    <div class="page-content">
        <div class="blobs">
            <blob class="blob-1 c-4" style="--p:65;" />
            <blob class="blob-2 c-1" style="--p:180;" />
            <blob class="blob-3 c-3" style="--p:33;" />
            <blob class="blob-4 c-2" style="--p:70;" />
        </div>

        <div class="box">
            <h1 class="title-h2">{{ copydeck.intro.title }}</h1>
            <div v-html="copydeck.intro.text"></div>
        </div>

        <div class="page-buttons">
            <router-link class="btn" to="quiz">{{ copydeck.button.continue }}</router-link>
        </div>
    </div>

    <navbar />
</template>

<script>
    import Navbar from "../components/Navbar";
    import Blob from "../components/Blob";

    export default {
        name: "Intro",
        components: {Blob, Navbar}
    }
</script>

<script setup>
    import useCopydeck from "../composables/UseCopydeck";
    const copydeck = useCopydeck();
</script>
