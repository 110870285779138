<template>

    <form class="page-content menu" :action="`/${lang}/questions/`" @submit="handleSubmit">
        <blob />

        <div class="box">
            <div class="form-control">
                <label for="where_from" class="label title-h2">{{ copydeck.menu.where_from }}</label>
                {{ copydeck.menu.im_from }}

                <select id="where_from" v-model="location">
                    <option value="">{{ copydeck.menu.dropdown_text }}</option>
                    <option v-for="province in provinces" :value="province.id">{{ province.text }}</option>
                </select>
            </div>

            <div class="form-control">
                <label for="how_old" class="label title-h2">{{ copydeck.menu.how_old }}</label>
                {{ copydeck.menu.i_am }}
                <select id="how_old" v-model="age">
                    <option value="">{{ copydeck.menu.dropdown_text }}</option>
                    <option v-for="range in age_ranges" :value="range">{{ range }}</option>
                </select>
                {{ copydeck.menu.years_old }}
            </div>
        </div>

        <div class="page-buttons">
            <button class="btn" type="submit" :disabled="!userIsReady">{{ copydeck.button.continue }}</button>
        </div>
    </form>

    <navbar />

</template>

<script>
    import Navbar from "../components/Navbar";
    import Blob from "../components/Blob";
    export default {
        name: "Menu",
        components: {Blob, Navbar}
    }
</script>

<script setup>

    import { storeToRefs } from "pinia";
    import { useMainStore } from "../stores/main";
    import { useRouter } from "vue-router";
    import useCopydeck from "../composables/UseCopydeck";
    import {computed} from "vue";
    import axios from "axios";

    let store = useMainStore();
    const { lang, user, userIsReady, uuid } = storeToRefs(store);

    const copydeck = useCopydeck();

    const router = useRouter();

    let provinces = computed ( () => copydeck.about_you.provinces[lang.value].split("|").map( p => ({id:p.split(":")[0], text:p.split(":")[1]})));
    let age_ranges = computed ( () => copydeck.about_you.age_ranges[lang.value].split("|"));

    let location = computed (
        {
            get () { return user.value.location ?? ""},
            set (val) { user.value.location = val }
        });

    let age = computed (
        {
            get () { return user.value.age ?? ""},
            set (val) { user.value.age = val }
        });

    function handleSubmit(event)
    {
        event.preventDefault();

        const data = { uuid: uuid.value, lang: lang.value, age: age.value, province: location.value};
        axios.post("/endpoints/save-user-info", data);

        router.push({name: "Intro", params:{lang: lang.value }} )
    }
</script>
