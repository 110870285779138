<template>

    <div class="page-content thankyou">
        <div class="blobs">
            <blob class="blob-1 c-4" style="--p:65;" />
            <blob class="blob-2 c-1" style="--p:180;" />
            <blob class="blob-3 c-3" style="--p:33;" />
            <blob class="blob-4 c-2" style="--p:70;" />
        </div>

        <div class="box">
            <div class="box-content">
            <h1 class="title-h2" v-if="isSecondTime">{{ copydeck.end.title }}</h1>

            <fieldset class="form-control" v-if="isFirstTime">
                <legend class="label">{{ copydeck.statements.question1 }}</legend>

                <div class="yesno">
                    <label class="choice-yes" :class="{ 'active': q1_answer === 'yes'}">
                        <input v-model="question1" type="radio" class="check-yes" name="question_1" id="question_1_yes" value="yes" />
                        <span>{{ copydeck.text.yes }}</span>
                    </label>
                    <label class="choice-no" :class="{ 'active': q1_answer === 'no'}">
                        <input v-model="question1" type="radio" class="check-no" name="question_1" id="question_1_no" value="no" />
                        <span>{{ copydeck.text.no }}</span>
                    </label>
                </div>
            </fieldset>
            </div>
        </div>

    </div>

    <div class="page-buttons mobile-rows" v-if="isFirstTime">
        <button class="btn" type="button" :disabled="!canSubmit" @click="nextStep">{{ copydeck.button.continue }}</button>
    </div>

    <div class="page-buttons mobile-rows" v-if="isSecondTime">
        <button class="btn" type="button" @click="nextStep">{{ copydeck.end.button }}</button>
        <router-link class="link" to="info">{{ copydeck.end.link }}</router-link>
    </div>

    <navbar />

</template>

<script>
    import Navbar from "../components/Navbar";
    import Blob from "../components/Blob";

    export default {
        name: "ThankYou",
        components: {Blob, Navbar}
    }
</script>

<script setup>

    import { useRouter }     from "vue-router";
    import useCopydeck       from "../composables/UseCopydeck";
    import { useMainStore } from "../stores/main";
    import { computed, ref } from "vue";
    import axios             from "axios";
    import {storeToRefs}     from "pinia";

    const copydeck = useCopydeck();
    const router = useRouter();
    let store = useMainStore();
    const { lang, uuid, i_will_take_action, sequenceIndex } = storeToRefs(store);

    let isFirstTime = computed ( () => sequenceIndex.value === 0 );
    let isSecondTime = computed ( () => sequenceIndex.value === 1  );

    let question1 = ref(null);

    let canSubmit = computed( () => question1.value !== null);
    let q1_answer = computed( () => question1.value);

    function nextStep()
    {
        const data = {
            uuid: uuid.value,
            lang: lang.value,
            quiz_help: q1_answer.value === "yes" ? 1 : 0
        };
        axios.post("/endpoints/save-user-feedback", data);

        if(isFirstTime.value) {
            router.push( {name: "QuizEnd-" + lang.value} );
        } else {
            router.push({name: "Splash"});
        }
    }
</script>
