<template>

    <li class="answer" :id="`answer_${index}`" :class="className" @click="handleClick(index)" ref="refRoot">
        <figure class="answer-img" aria-hidden="true">
            <img :src="answer.image_monochrome.url" v-if="showAnswer" />
            <img :src="answer.image.url" v-if="!showAnswer" />
        </figure>

        <div class="answer-letter">
            <span v-if="!showAnswer">{{ getLetter(index) }}</span>
            <blob :class="{ 'dot' : !showAnswer }" :percent="(showAnswer)? percent : null" />
        </div>

        <label class="answer-text" :for="`question_${questionIndex}_a_${index}`" v-html="answer.answer[lang]"></label>

        <input type="radio"
            :name="`question_${questionIndex}`"
            :id="`question_${questionIndex}_a_${index}`"
            :disabled="showAnswer"
            :checked="picked"
            v-model="picked"
        />

        <div class="answer-number" :class="{'hidden' : !showAnswer}">
            <counter :value="(showAnswer)? answer.carbon_grams : 0" :locale="`${lang}-CA`"></counter>
        </div>

        <div v-if="showAnswer && isMostPopular" class="most-common">
            <span>{{ copydeck.quiz.most_common }}</span>
        </div>
    </li>

</template>

<script>
    import Blob from "./Blob";
    import Counter from "./Counter";
    export default {
        name: "Answer",
        components: { Blob, Counter }
    }
</script>

<script setup>

    import { useMainStore } from "../stores/main";
    import { storeToRefs }  from "pinia";
    import { computed, ref } from "vue";
    import useCopydeck from "../composables/UseCopydeck";

    let props = defineProps(["answer", "index", "questionIndex", "showAnswer", "handleClick"]);

    let store = useMainStore();
    const { lang, questions } = storeToRefs(store);
    const copydeck = useCopydeck();

    let percent = computed ( () => parseFloat(props.answer.percent));
    let isSelected = computed ( () => picked.value);

    let question = computed ( () => questions.value[props.questionIndex - 1]);
    let mostPopular = computed ( () => question.value.most_popular );
    let allPopular = computed ( () => mostPopular.value.map ( mp => mp.popular ));
    let mostPopularValue = computed ( () => Math.max(...allPopular.value));
    let mostPopularAnswer = computed ( () => question.value.most_popular.filter( mp => mp.popular === mostPopularValue.value).map( mp => mp.index_answer));
    let isMostPopular = computed ( () => mostPopularAnswer.value.includes(props.index));




    let answer = computed ( () => props.answer);

    let picked = ref(false);
    let refRoot = ref(null);

    let strEventClass = computed ( () => props.showAnswer ? ' no-events' : '' )
    let className = computed ( () => `a-${props.index + 1}` + strEventClass.value );

    function setPicked (val)
    {
        picked.value = val;
    }

    function getLetter (index)
    {
        return ["A","B","C","D"][index];
    }

    function getPosition (maxRatio = 1)
    {
        let rect = refRoot.value.getBoundingClientRect();

        let pct = parseInt(answer.value.percent)
        let ratio = pct / 100;

        let x = rect.x + rect.width / 2;
        let y = ((rect.height - rect.y) *  ratio) * maxRatio;

        return ({ x, y });
    }

    defineExpose ({ isSelected, answer, refRoot, setPicked, getPosition })

</script>
