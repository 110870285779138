<template>
    <div class="page-content splash">

        <div class="blobs">
            <blob class="blob-1 c-4" style="--p:65;"/>
            <blob class="blob-2 c-1" style="--p:180;"/>
            <blob class="blob-3 c-3" style="--p:33;"/>
            <blob class="blob-4 c-2" style="--p:70;"/>
        </div>

        <div class="btn-box" @click="(e) => setLang(e, 'en')">
            <h1 class="title-logo">Mission <strong>Zero</strong></h1>
            <p>{{ copydeck.splash.title['en'] }}</p>
            <router-link to="/en" class="btn">{{ copydeck.splash.bouton['en'] }}</router-link>
        </div>
        <div class="btn-box" @click="(e) => setLang(e, 'fr')">
            <h1 class="title-logo">Mission <strong>zéro</strong></h1>
            <p>{{ copydeck.splash.title['fr'] }}</p>
            <router-link to="/fr" class="btn">{{ copydeck.splash.bouton['fr'] }}</router-link>
        </div>
    </div>

    <div class="logos">
        <a :href="logoLink" target="_blank">
            <img src="/images/ingenium.svg" alt="Ingenium logo" />
        </a>
        <img src="/images/wordmcanada.svg" alt="Canada logo" class="logo-smaller" />
    </div>

    <navbar />
</template>

<script>
import Blob from "../components/Blob";
import Navbar from "../components/Navbar";

export default {
    name: "Splash",
    components: { Blob, Navbar }
}
</script>

<script setup>

    import { useMainStore } from "../stores/main";
    import { useRouter } from 'vue-router'
    import useCopydeck from "../composables/UseCopydeck";
    import { computed, onMounted } from "vue";
    import {storeToRefs} from "pinia";

    let store = useMainStore();
    const { changeLang, reset } = store;
    const { lang, userIsReady } = storeToRefs(store);

    const copydeck = useCopydeck();
    const router = useRouter();

    let logoLink = computed(() => (lang.value === 'en')? 'https://ingeniumcanada.org/' : 'https://ingeniumcanada.org/fr');

    function setLang (event, lang)
    {
        event.preventDefault();
        changeLang(lang);

        let strLang = lang;

        router.push({name: `Menu-${strLang}`, params:{ lang }} )
    }

    function mounted ()
    {
        reset();
    }

    onMounted ( mounted )

</script>
