<template>
    {{ formatted }} g
</template>
<script>
    import gsap           from 'gsap';


    export default {
        name: 'animate-integer',
        props: {
            value: { default: 0 },
            duration: { default: 0.5 },
            formatter: { type: Function, default: (n) => {
                return new Intl.NumberFormat().format(n);
            }},
            locale: { default: "en-CA"}
        },
        data() {
            return {
                displayValue: Math.ceil(this.value),
                tweenValue: this.value
            };
        },
        computed: {
            formatted() {

                return this.displayValue.toLocaleString(this.locale)
            }
        },
        watch: {
            value() {
                gsap.to(this, {
                    tweenValue: Math.ceil(this.value),
                    duration: this.duration,
                    onUpdate: () => {
                        this.displayValue = Math.round(this.tweenValue);
                    },
                    onComplete: () => {
                        this.displayValue = Math.round(this.value);
                    }
                });
            }
        }
    };
</script>
