<template>
    <li class="action" :class="`c-${index + 1}`" @click="handleClick(index, !picked)">
        <figure class="action-image" aria-hidden="true">
            <img :src="imageSrc" alt="" />
        </figure>
        <input type="checkbox"
            class="action-choose"
            :name="`action_${index}`"
            :id="`action_${index}`"
            :checked="picked"
            v-model="picked"
        />
        <label :for="`action_${index}`" class="action-text" v-html="actionDescription"></label>
    </li>
</template>

<script>
    export default {
        name: "Action"
    }
</script>

<script setup>

    import { useMainStore } from "../stores/main";
    import { storeToRefs }  from "pinia";
    import { computed, ref } from "vue";

    let props = defineProps(["index", "currentQuestion", "handleClick"]);

    let store = useMainStore();

    let { questions, lang } = storeToRefs(store);

    let picked = ref(false);
    let isSelected = computed ( () => picked.value);

    let question = props.currentQuestion;
    let actionDescription = computed(() => question.take_action.text[lang.value]);
    let imageSrc = question.take_action.image;

    let action = computed ( () => question.take_action.type );
    function setPicked (val)
    {
        picked.value = val;
    }

    defineExpose ({ action, isSelected, setPicked });

</script>
