<template>
    <li :class="`c-${index + 1}`">
        <div class="result-score" aria-hidden="true">
            <div v-if="compare" class="previous-answer" :style="`--p: ${percentCompare};`"></div>
            <!--<blob :percent="(ready.value)? percentCurrent : null" />//-->
            <blob :percent="percentCurrent" />
        </div>
        {{category}}
    </li>
</template>

<script>
    import Blob from "./Blob";
    export default {
        name: "Result",
        components: { Blob }
    }
</script>

<script setup>

    import { ref, computed, onMounted } from "vue";
    import { useMainStore } from "../stores/main";
    import { storeToRefs }  from "pinia";

    let props = defineProps(["index", "sequenceIndex"]);

    let store = useMainStore();

    let {userAnswers, questions, lang} = storeToRefs(store);

    let category = computed( () => questions.value[props.index].questions.category[lang.value]);

    let compare = computed( () => (props.sequenceIndex === 1) );
    let percentCompare = parseInt(userAnswers.value[0][props.index].percent ?? 0);

    let ready = ref(false);
    let percentCurrent = computed( () => (ready.value)? parseInt(userAnswers.value[props.sequenceIndex][props.index].percent ?? 0) : 0 );

    function mounted ()
    {
        setTimeout(function() {
            ready.value = true;
        }, 500);
    }
    onMounted( mounted );


</script>
