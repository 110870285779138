// For clarity, imports are ordered according to flow (order in which the pages are viewed)
import Splash       from "./pages/Splash";
import Menu         from "./pages/Menu";
import Intro        from "./pages/Intro";
import Quiz         from "./pages/Quiz";
import Results      from "./pages/Results";
import Choices      from "./pages/Choices";
import QuizEnd      from "./pages/QuizEnd";
import TakeAction   from "./pages/TakeAction";
import ThankYou     from "./pages/ThankYou";
import Info         from "./pages/Info";

const routes =
             [
                 {
                     path: "/",
                     name: "Splash",
                     component: Splash
                 },

                 {
                     path: "/:lang",
                     name: "SplashWithLang",
                     component: Splash
                 },


                 {
                     path: "/:lang(fr)/a-propos-de-toi",
                     name: "Menu-fr",
                     component: Menu
                 },

                 {
                     path: "/:lang(en)/about-you",
                     name: "Menu-en",
                     component: Menu
                 },



                 {
                     path: "/:lang/intro",
                     name: "Intro",
                     component: Intro
                 },


                 {
                     path: "/:lang/quiz",
                     name: "Quiz",
                     component: Quiz
                 },


                 {
                     path: "/:lang(fr)/resultats",
                     name: "Results-fr",
                     component: Results
                 },

                 {
                     path: "/:lang(en)/results",
                     name: "Results-en",
                     component: Results
                 },

                 {
                     path: "/:lang(fr)/les-choix-sont-importants",
                     name: "Choices-fr",
                     component: Choices
                 },

                 {
                     path: "/:lang(en)/all-choices-matter",
                     name: "Choices-en",
                     component: Choices
                 },

                 {
                     path: "/:lang(fr)/fin-du-quiz",
                     name: "QuizEnd-fr",
                     component: QuizEnd
                 },

                 {
                     path: "/:lang(en)/quiz-end",
                     name: "QuizEnd-en",
                     component: QuizEnd
                 },

                 {
                     path: "/:lang(fr)/engage-toi",
                     name: "TakeAction-fr",
                     component: TakeAction
                 },

                 {
                     path: "/:lang(en)/take-action",
                     name: "TakeAction-en",
                     component: TakeAction
                 },

                 {
                     path: "/:lang(fr)/merci",
                     name: "ThankYou-fr",
                     component: ThankYou
                 },

                 {
                     path: "/:lang(en)/thank-you",
                     name: "ThankYou-en",
                     component: ThankYou
                 },

                 {
                     path: "/:lang/info",
                     name: "Info",
                     component: Info
                 },
             ]


export default routes;
