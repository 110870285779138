<template>

    <div class="question" :class="`c-${questionIndex + 1}`">

        <ProgressBar />

        <div class="page-content didyouknow" v-if="isDidYouKnow">
            <Blob />

            <div class="box">
                <p class="title-h2">{{ copydeck.didyouknow.title }}</p>
                <p v-html="question.did_you_know.title[lang] "></p>
                <p><strong v-html="question.did_you_know.text[lang]"></strong></p>
            </div>
            <div class="bg-image mobile-hide" aria-hidden="true">
                <img :src="question.did_you_know.image" />
            </div>

            <div class="page-buttons">
                <button type="button" class="btn" @click="nextQuestion">{{ copydeck.button.continue }}</button>
            </div>
        </div>

        <div v-else class="fieldset" :class="{ 'answered' : isAnswered }">

            <div v-html="title" class="question-title"></div>

            <ul class="answers">
                <answer
                        v-for="(currentAnswer,index) in answers"
                        :index="index"
                        :questionIndex="questionIndex + 1"
                        :answer="currentAnswer"
                        :showAnswer="isAnswered"
                        :handleClick="selectAnswer"
                        ref="refAnswers"
                ></answer>
            </ul>

            <p class="msg-error" v-if="isError && !canSubmit">{{ copydeck.form_error.one }}</p>

            <div class="page-buttons">
                <button type="button" class="btn" v-if="!isAnswered" :disabled="!canSubmit" @click="confirmAnswer">{{ copydeck.question.button }}</button>
                <button type="button" class="btn" v-if="isAnswered" @click="nextStep">{{ copydeck.button.continue }}</button>
            </div>
        </div>
        <curve ref="refCurve"></curve>

    </div>



</template>

<script>
    import Answer from "./Answer";
    import Blob from "./Blob";
    import ProgressBar from "./ProgressBar";
    import Curve             from "./Curve";

    export default {
        name: "Question",
        components: { Answer, Blob, ProgressBar, Curve }
    }
</script>

<script setup>

    import { computed, ref, onMounted } from "vue";
    import { useMainStore }  from "../stores/main";
    import { storeToRefs }   from "pinia";
    import useCopydeck       from "../composables/UseCopydeck";
    import { gsap, Power2 } from "gsap";
    import  axios  from "axios";
    //import { event } from 'vue-gtag'
    import { event } from "../composables/UseGtag";

    let props = defineProps(["question", "questionIndex"]);

    const copydeck = useCopydeck();

    let store = useMainStore();
    const { lang, uuid, sequenceIndex } = storeToRefs(store);
    const { changeQuestion, setUserAnswer, increaseQuestionPopularity } = store;

    let step = ref('question');
    let refAnswers = ref([]);
    let answersRect = [];
    let refCurve = ref(null);

    const title = computed ( () => props.question.questions.title[lang.value]);
    const answers = computed ( () => props.question.answers);


    let isAnswered = computed ( () => step.value === 'answered');
    let isDidYouKnow = computed ( () => step.value === 'didyouknow');
    let isSecondTime = computed ( () => sequenceIndex.value === 1  );

    let canSubmit = computed( () => refAnswers.value && refAnswers.value.find ( a => a.isSelected ) );
    let isError = false; // Maybe display error message (on hold)


    let currentRefAnswers = computed ( () => refAnswers.value )

    function selectAnswer(index)
    {
        if (!isAnswered.value)
        {
            refAnswers.value.forEach ( a => a.setPicked(false) );

            let selectedAnswer = refAnswers.value[index];
            selectedAnswer.setPicked(true);
        }
    }

    function animateCurve ()
    {
        let obj = { anim: 0 };

        gsap.to( obj, { anim: 1, duration: 1.5, ease: Power2.easeOut, onUpdate: () => drawCurve(obj.anim)});
    }

    function resetCurve ()
    {
        let obj = { anim: 1 };

        gsap.to( obj, { anim: 0, duration: 1, ease: Power2.easeOut, onUpdate: () => drawCurve(obj.anim)});
    }

    function drawCurve ( ratio, answers)
    {
        let position = JSON.parse(JSON.stringify(answersRect)); //clone answers
        position.forEach( p => p.y = p.y * ratio);

        refCurve.value.drawCurve(position)
    }


    async function confirmAnswer()
    {
        let selectedAnswer = refAnswers.value.find ( a => a.isSelected );

        if (selectedAnswer)
        {
            step.value = 'answered';
            let answer = selectedAnswer.answer;
            let selectedAnswerIndex = refAnswers.value.findIndex ( a => a.isSelected );
            setUserAnswer(answer);

            let top = document.getElementById(`answer_${selectedAnswerIndex}`).offsetTop;
            window.scrollTo(0, top);

            animateCurve();

            const data = {
                id_question:  props.question.id,
                index_answer: selectedAnswerIndex,
                sequence_index: sequenceIndex.value,
                lang: lang.value,
                uuid: uuid.value };

            if (sequenceIndex.value === 0)
            {
                increaseQuestionPopularity(props.question, selectedAnswerIndex);
            }

            let response = await axios.post("/endpoints/save-answer", data); // we don't really care about the response

        }

    }



    function nextStep()
    {
        if(isSecondTime.value)
        {
            resetCurve()
            nextQuestion();
        }
        else
        {
            step.value = "didyouknow";
            window.scrollTo(0, 0)
            resetCurve()
        }
    }

    function nextQuestion( )
    {
        step.value = "question";
        changeQuestion( props.questionIndex + 1 );
        refAnswers.value.forEach ( a => a.setPicked(false) );
        setTimeout( () => answersRect = refAnswers.value.map( a => a.getPosition(1)), 100);
        window.scrollTo(0, 0)


        event('quiz', "Question", "Question" + (props.questionIndex + 1))
    }


    function mounted ()
    {
        event('quiz', "Question", "Question" + (props.questionIndex + 1))
        refCurve.value.drawCurve([])
        answersRect = refAnswers.value.map( a => a.getPosition(1));
    }

    onMounted( mounted )

</script>
