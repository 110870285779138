
import {useMainStore} from "../stores/main"
import {storeToRefs}  from "pinia";
import { computed } from "vue";
import { useTitle }            from '@vueuse/core';


let store;
let lang;

//let idByLang = computed ( () => ({en: "GTM-NRZNSKC", fr:"GTM-KMVCR7D"})[lang.value] ); //mission zero lang
let arrIds = computed ( () => ['G-D0KJEKHQRG', googleTag ?? 'GTM-NRZNSKC']); //pla + ingenium (from the cms)
let title = useTitle();

export function pageView ()
{
    if (!store) //first time we instantiate, we will link to the store
    {
        store = useMainStore();

        ({lang} = storeToRefs(store)); //parentheses are needed to destructure into existing variable
    }

    let strTitle = title.value;
    let strUrl = document.location.href;

    arrIds.value.forEach ( id =>
                           {
                               gtag('config', id, {
                                   page_title : strTitle,
                                   page_location: strUrl
                               });
                           })
}

export function event (type, category, label)
{
    if (!store) //first time we instantiate, we will link to the store
    {
        store = useMainStore();

        ({lang} = storeToRefs(store)); //parentheses are needed to destructure into existing variable
    }

    gtag('event', type,
         {
             'send_to' : arrIds.value.join("/"),
             'event_category' : category,
             'event_label' : label
         });

}

