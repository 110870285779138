<template>
    <div class="blob" :class="cssClass" :style="`--p: ${percent};`" aria-hidden="true">
        <svg ref="lefteye" viewBox="0 0 33 33" fill="none"
             class="left-eye"
             :style="`transform: rotate(${rotationDegrees - 40}deg)`"
        >
            <path d="M0 16.3895C0 25.4415 7.33733 32.7788 16.388 32.7788C25.44 32.7788 32.7773 25.4415 32.7773 16.3895C32.7773 7.33748 25.44 0.000144958 16.388 0.000144958C7.33733 0.000144958 0 7.33748 0 16.3895Z" fill="#ffffff"/>
            <path d="M18.901 12.1063C21.109 14.5503 24.4383 15.1396 26.3356 13.4249C28.2343 11.7103 27.9823 8.33826 25.773 5.89559C23.5636 3.45159 20.2356 2.86093 18.3383 4.57693C16.4396 6.29159 16.693 9.66226 18.901 12.1063Z" fill="#000000"/>
        </svg>
        <svg ref="righteye" viewBox="0 0 33 33" fill="none"
             class="right-eye"
             :style="`transform: rotate(${rotationDegrees - 40}deg)`"
        >
            <path d="M0 16.3895C0 25.4415 7.33733 32.7788 16.388 32.7788C25.44 32.7788 32.7773 25.4415 32.7773 16.3895C32.7773 7.33748 25.44 0.000144958 16.388 0.000144958C7.33733 0.000144958 0 7.33748 0 16.3895Z" fill="#ffffff"/>
            <path d="M18.901 12.1063C21.109 14.5503 24.4383 15.1396 26.3356 13.4249C28.2343 11.7103 27.9823 8.33826 25.773 5.89559C23.5636 3.45159 20.2356 2.86093 18.3383 4.57693C16.4396 6.29159 16.693 9.66226 18.901 12.1063Z" fill="#000000"/>
        </svg>
    </div>
</template>

<script>
    export default {
        name: "Blob"
    }
</script>

<script setup>
    import { computed, ref } from "vue";
    import {
        debouncedWatch,
        throttledWatch,
        useMouse,
        useWindowSize,
    } from "@vueuse/core";

    import {useRandomCoordinate} from "../composables/useRandomCoordinate";

    let props = defineProps(["percent"]);

    let cssClass = computed ( () => (parseFloat(props.percent) >= 90)? 'max' : '' );

    const { x: mouseX, y: mouseY } = useMouse();
    const { width, height } = useWindowSize();

    useRandomCoordinate(onMoveRandomly);

    let lefteye = ref(null);
    const lefteyeLocation = computed(() => lefteye?.value?.getBoundingClientRect() ?? { });

    const rotationDegrees = ref(0);
    const goalRotationDegrees = ref(0);

    let isAnimating = ref(false);


    function onMoveRandomly (randomX, randomY)
    {

        calculateRotationalAngle(randomX, randomY);
    }

    // let randomX = Math.random() * window.innerWidth;
    // let randomY = Math.random() * window.innerHeight;
    //
    // function moveMouseRandomly (moveMouse = true)
    // {
    //     randomX = Math.random() * window.innerWidth;
    //     randomY = Math.random() * window.innerHeight;
    //
    //     timerMoveRandomly = setTimeout ( () => moveMouseRandomly(), 2000 + Math.random() * 2000); //move randomly each 10 to 20 sec
    //     calculateRotationalAngle(randomX, randomY);
    //     console.log("moveMouseRandomly")
    // }
    //
    // let timerMoveRandomly = setTimeout ( () => moveMouseRandomly(), 2000 + Math.random() * 2000); //move randomly each 10 to 20 sec
    // //moveMouseRandomly(false);

    // debouncedWatch(
    //     [width, height],
    //     () => {
    //         lefteyeLocation.value = lefteye.value.getBoundingClientRect();
    //     },
    //     { debounce: 200 }
    // );

    throttledWatch(
        [mouseX, mouseY],
        ([x, y]) =>
        {
            if (lefteyeLocation.value)
            {
                calculateRotationalAngle(x,y);
            }
        },
        { throttle: 1000 / 60 }
    );

    function calculateRotationalAngle (x, y)
    {
        // This calculates the radian offset between the eye and the mouse
        const radianDegrees = Math.atan2(
            x - lefteyeLocation.value.right,
            y - lefteyeLocation.value.top
        );
        // Now we need to convert it into a degrees value we can use in css
        goalRotationDegrees.value = radianDegrees * (180 / Math.PI) * -1 + 180;
        // rotationDegrees.value = radianDegrees * (180 / Math.PI) * -1 + 180;
        //console.log(x,y, mouseX, mouseY);

        if (!isAnimating.value)
        {
            moveEyesTowardGoal();
        }
    }
    function moveEyesTowardGoal () //trying to smooth the movement of the eye
    {
        isAnimating.value = true;
        let diff = (goalRotationDegrees.value - rotationDegrees.value) ;

        let normGoal = goalRotationDegrees.value <= 180 ? goalRotationDegrees.value : goalRotationDegrees.value - 360
        let normCurrent = rotationDegrees.value <= 180 ? rotationDegrees.value  : rotationDegrees.value  - 360

        normGoal += 45;
        normCurrent += 45;

        let diff2 = (normGoal) - (normCurrent);


        if (Math.abs (diff - diff2) > 180) //sometime we go from 359 to 1 and it look weird, this is an so-so try to solve this
        {
            if (Math.abs(diff) > Math.abs(diff2))
            {
                diff = diff2;
            }
        }

        rotationDegrees.value += diff / 20;



       // if (Math.abs(diff / 100) > 1)
        //we should stop that at some point
        {
            requestAnimationFrame( () => moveEyesTowardGoal());
        }
    }
</script>
